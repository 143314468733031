<template>
  <v-card>
    <v-card-title>
      {{ this.$t("erp.lang_nav_waregroups") }}

      <v-spacer></v-spacer>

      <v-btn @click="openDiscountDialog" depressed color="success">
        {{ $t('generic.lang_discount') }}
      </v-btn>
    </v-card-title>
    <v-container class="ma-0 pa-0" fluid>
      <v-card elevation="0" outlined>
        <v-card-text class="ma-0 pa-0">
          <v-card elevation="0">
            <v-card-title class="pa-0 ma-0">
              <div class="card-header-tab card-header w-100">
                <div
                  class="
                    card-header-title
                    font-size-lg
                    text-capitalize
                    font-weight-normal
                  "
                >
                  <b-input-group width="100%">
                    <b-form-input
                      :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :placeholder="$t('datatables.lang_search')"
                      @focus="showTouchKeyboard"
                      v-model="search"
                    />
                  </b-input-group>
                </div>
              </div>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :footer-props="footerProps"
                :headers="filteredDatatableHeaders"
                :items="filteredDatatableEntries"
                :loading="loading"
                sortBy="position"
                :options.sync="pagination"
                :server-items-length="total"
                footer-props.items-per-page-text="Einträge pro Seite"
                v-model="selectedRows"
              >
                <template v-slot:body="props">
                  <draggable
                    :list="props.items"
                    tag="tbody"
                    v-bind="dragOptions"
                    @end="changePosition($event)"
                  >
                    <tr v-for="(waregroup, index) in props.items" :key="index">
                      <td>
                        <font-awesome-icon
                          :icon="['fas', 'grip-vertical']"
                          style="color: lightgray; cursor: pointer"
                          class="page__grab-icon text-lighter handle"
                        />
                      </td>
                      <td v-show="false">
                        {{ waregroup.id }}
                      </td>

                      <td>{{ waregroup.category }}</td>

                      <td>
                        <b-form-input
                          :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          type="number"
                          @blur="editEntry(waregroup)"
                          @keypress.enter="editEntry(waregroup)"
                          v-model="waregroup.position"
                        />
                      </td>

                      <td>
                        <b-form-input
                          :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          type="number"
                          @blur="editEntry(waregroup)"
                          @keypress.enter="editEntry(waregroup)"
                          v-model="waregroup.self_service_discount"
                          @keyup="
                            discountInput(waregroup, 'self_service_discount')
                          "
                        />
                      </td>

                      <td>
                        <b-input-group>
                          <!-- <b-form-input :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        @blur="editEntry(item )" @keypress.enter="editEntry(waregroup)"
                                        v-model="waregroup.self_service_alias"/>
                          <b-input-group-append is-text>
                            <input type="checkbox" v-model="waregroup.self_service_active" :value="1"
                                   @change="editEntry(waregroup)" aria-label="Checkbox for following text input">
                          </b-input-group-append>-->

                          <b-input-group-append is-text>
                            <input
                              type="checkbox"
                              v-model="waregroup.self_service_active"
                              @change="editEntry(waregroup)"
                              :value="true"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <!--start: discount dialog-->
          <v-dialog max-width="600" v-model="discount_dialog">
            <v-card>
              <v-card-title> {{ $t('generic.lang_bulk_discount') }} </v-card-title>
              <v-card-text class="py-2 px-3">
                <v-form v-on:submit.prevent lazy-validation ref="discountForm">
                  <v-text-field
                    @keypress.enter="applyDiscount"
                    :rules="[
                      (v) => !!v && parseFloat(v) >= 0 && parseFloat(v) <= 100,
                    ]"
                    :max="100"
                    :min="0"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    @focus="showTouchKeyboard"
                    type="number"
                    hide-details
                    dense
                    outlined
                    v-model="global_discount"
                    :label="$t('generic.lang_discount')"
                  />
                </v-form>
              </v-card-text>

              <v-card-actions class="py-2 px-3">
                <v-btn
                  :loading="this.discountLoader"
                  :disabled="this.discountLoader"
                  @click="applyDiscount"
                  depressed
                  color="primary"
                  block
                  class="mx-0"
                >
                  {{ this.$t("generic.lang_apply") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--end: discount dialog-->

          <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard
              :accept="hideTouchKeyboard"
              :cancel="hideTouchKeyboard"
              :defaultKeySet="touchKeyboard.keySet"
              :input="touchKeyboard.input"
              :layout="touchKeyboard.layout"
              :options="touchKeyboard.options"
              class="internalWidthExpanded"
              id="onScreenKeyboard"
              v-if="touchKeyboard.visible"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import draggable from "vuedraggable";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";

library.add(faGripVertical);

export default {
  name: "tableBeeCategoriesComponent",
  components: {
    draggable,
    "font-awesome-icon": FontAwesomeIcon,
  },
  mixins: [mixin],
  data() {
    return {
      discount_dialog: false,
      global_discount: "",
      discountLoader: false,
      /*
      * lb_take_away_discount:
self_service_alias:
self_service_discount:
self_service_active:
* array(
 'id', 'cat_name_de', 'lbWareCatName', 'lbWareGroupDiscount', 'lbWareCatActive', 'lbWareCatPosition',
 'lb_take_away_discount', 'self_service_alias', 'self_service_discount', 'self_service_active'
)

      * */
      datatableHeaders: [
        {
          text: "",
          value: "crud",
          sortable: false,
        },
        {
          text: "ID",
          value: "id",
          hidden: true,
        },
        {
          text: this.$t("erp.lang_itemgroup_name"),
          value: "category",
        },
        {
          text: this.$t("generic.lang_sorting"),
          value: "position",
          align: "center",
          width: "100",
        },
        {
          text: this.$t("generic.lang_alias"),
          value: "self_service_alias",
          width: 200,
          hidden: true,
        },
        {
          text: this.$t("generic.lang_discount"),
          value: "self_service_discount",
          width: "100",
        },
        {
          text: this.$t("generic.lang_enabled"),
          value: "self_service_active",
          width: "100",
        },
      ],
      loading: false,
      drag: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [
        [1, "cat1", "localbee cat", 50, 1, 2],
        [2, "cat2", "localbee brot", 10, 1, 3],
        [3, "cat3", "localbee snacks", 10, 1, 4],
        [4, "cat4", "localbee cakes", 10, 1, 5],
        [5, "cat5", "localbee ice cream", 10, 1, 6],
      ],
      search: "",
      total: 10,
      pagination: {},
      footerProps: { "items-per-page-options": [15, 30, 50, 100] },
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
    };
  },
  computed: {
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hidden")) {
          if (header.hidden) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];
      if (this.dataToShow)
        this.dataToShow.forEach((data) => {
          let row = {};
          let index = 0;
          this.datatableHeaders.forEach((header) => {
            if (header.value !== "crud") {
              if (header.value === "position") {
                row[header.value] = parseInt(Number(data[index]));
              } else {
                row[header.value] = data[index];
              }
            } else {
              return;
            }
            index++;
          });
          dataToRender.push(row);
        });

      return dataToRender;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    },
  },
  methods: {
    openDiscountDialog() {
      this.discount_dialog = true;
      this.global_discount = "";
    },
    applyDiscount() {
      if (!this.$refs.discountForm.validate()) return;
      this.discountLoader = true;
      this.axios
        .post(ENDPOINTS.TABLEBEE.PRODUCTSGROUP.BULKDISCOUNT, {
          discount: this.global_discount,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            this.discount_dialog = false;
            this.getDataFromApi();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => (this.discountLoader = false));
    },
    discountInput(item, attr) {
      if (Number(item[attr]) > 100) {
        item[attr] = 100;
      }
    },
    editEntry(entry, event = null) {
      this.axios
        .post(ENDPOINTS.TABLEBEE.PRODUCTSGROUP.UPDATE, {
          id: entry.id,
          position: entry.position,
         // self_service_alias: entry.self_service_alias,
          self_service_discount: entry.self_service_discount,
          self_service_active: entry.self_service_active,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            this.getDataFromApi();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        });
    },
    changePosition(event) {
      let item = this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced = this.filteredDatatableEntries[event.newIndex];
      if (event.oldIndex < event.newIndex) {
        if (item.id !== itemReplaced.id)
          item.position = parseInt(Number(itemReplaced.position)) + 1;
      } else if (event.oldIndex > event.newIndex) {
        if (item.id !== itemReplaced.id)
          if (parseInt(Number(itemReplaced.position)) - 1 === 0) {
            item.position = 1;
          } else {
            item.position = parseInt(Number(itemReplaced.position)) - 1;
          }
      }
      if (item.id !== itemReplaced.id) {
        this.editEntry(item);
      }
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart:
          this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(
          (header) => header.value === this.pagination.sortBy[0]
        );
        index -= 1;
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? "desc" : "asc";
      }

      /* SPECIFY ADDITIONAL PARAMS
       * params['customerID'] = parseInt(this.$props.customerId);
       *
       * */

      this.axios
        .get(ENDPOINTS.DATATABLES.TABLEBEE.PRODUCTSGROUP, {
          params: params,
        })
        .then((res) => {
          this.total = parseInt(Number(res.data.iTotalDisplayRecords));
          this.dataToShow = res.data.aaData;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
          this.dataToShow = [];
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getDataFromApi();
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
